@import '../../Colours.scss';

.skill {
  margin-right: 0.7rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border: 1px solid $white-border;
  border-radius: 1rem;

  font-size: 1.5rem;
  font-weight: bolder;

  color: $white-text;

  &:hover {
    border: 1px solid $white;
    color: $white;
    cursor: pointer;
  }
}

.skill-clicked {
  border: 1px solid $white;
  color: $white;
}

@media (max-width: 750px) {
  .skill {
    font-size: 1.2rem;
  }
}

@media (max-width: 350px) {
  .skill {
    font-size: 1rem;
  }
}

@media (max-width: 310px) {
  .skill {
    font-size: 0.9rem;
  }
}

@media (max-width: 290px) {
  .skill {
    font-size: 0.8rem;
  }
}
