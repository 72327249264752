#title {
  margin-bottom: 20rem;

  #title-subtext {
    margin-top: 12rem;
    display: grid;
    grid-template-columns: 1fr 15fr;
    column-gap: 2rem;

    font-size: 2.5rem;
    font-weight: lighter;

    #emoji-1 {
      grid-row: 1;
      grid-column: 1;
      text-align: center;
      vertical-align: middle;
    }

    #emoji-2 {
      grid-row: 2;
      grid-column: 1;
      text-align: center;
      vertical-align: middle;
    }

    #emoji-3 {
      grid-row: 3;
      grid-column: 1;
      text-align: center;
      vertical-align: middle;
    }

    #text-1 {
      grid-row: 1;
      grid-column: 2;
      vertical-align: middle;
    }

    #text-2 {
      grid-row: 2;
      grid-column: 2;
      vertical-align: middle;
    }

    #text-3 {
      grid-row: 3;
      grid-column: 2;
      vertical-align: middle;
    }
  }
}

@media (max-width: 860px) {
  #title {
    #title-subtext {
      margin-top: 0rem;
    }
  }
}

@media (max-width: 800px) {
  #title {
    #title-subtext {
      font-size: 2.2rem;
    }
  }
}

@media (max-width: 600px) {
  #title {
    #title-subtext {
      margin-top: 8rem;
      font-size: 1.8rem;
    }
  }
}

@media (max-width: 450px) {
  #title {
    #title-subtext {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 360px) {
  #title {
    #title-subtext {
      font-size: 1.4rem;
    }
  }
}
