@import './Colours.scss';

#App {
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
  flex-direction: column;

  background-color: $black;
  color: $white;

  ::selection {
    background-color: $purple;
    color: $white;
  }
}

h1 {
  font-size: 8rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1.25rem;
}

a:-webkit-any-link {
  outline: none;
  text-decoration: none;
  color: $white;

  &:hover {
    color: $purple;
    cursor: pointer;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -6px, 0);
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -4px, 0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 7rem;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 3rem;
  }
}

@media (max-width: 350px) {
  h1 {
    font-size: 5rem;
  }
}

@media (max-width: 300px) {
  h3 {
    font-size: 1rem;
  }
}
