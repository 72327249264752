#skills {
  margin-bottom: 20rem;

  #skills-wrapper {
    display: flex;
    flex-direction: column;

    font-weight: lighter;

    #skills-backend-other {
      display: flex;
      flex-direction: row;

      #skills-backend {
        width: 67%;
        margin-right: 2rem;
        display: flex;
      }

      #skills-other {
        width: 33%;
        display: flex;
      }
    }
  }
}

@media (max-width: 640px) {
  #skills {
    #skills-wrapper {
      #skills-backend-other {
        display: flex;
        flex-direction: column;

        #skills-backend {
          width: 100%;
          margin-right: none;
        }

        #skills-other {
          width: 100%;
        }
      }
    }
  }
}
