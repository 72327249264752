@import '../../Colours.scss';

footer {
  height: 20vh;
  margin-left: -10vw;
  margin-right: -10vw;
  margin-bottom: 2rem;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  #footer-links {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: xx-large;

    #footer-links-linkedin {
      margin-right: 5rem;

      &:hover {
        animation: bounce 1s;
      }
    }

    #footer-links-github {
      &:hover {
        animation: bounce 1s;
      }
    }
  }

  #footer-email {
    font-size: 2rem;
    font-weight: lighter;

    &:hover {
      color: $purple;
      cursor: pointer;
    }
  }
}

@media (max-width: 700px) {
  footer {
    #footer-email {
      font-size: 1.7rem;
    }
  }
}

@media (max-width: 640px) {
  footer {
    #footer-email {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 610px) {
  footer {
    #footer-email {
      font-size: 1.4rem;
    }
  }
}

@media (max-width: 600px) {
  footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    #footer-links {
      order: 2;

      #footer-links-linkedin {
        margin-right: 5rem;
      }
    }

    #footer-email {
      margin-bottom: 3rem;
      order: 1;

      font-size: 2rem;
    }
  }
}

@media (max-width: 470px) {
  footer {
    #footer-email {
      font-size: 1.7rem;
    }
  }
}

@media (max-width: 380px) {
  footer {
    #footer-email {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 310px) {
  footer {
    #footer-email {
      font-size: 1.4rem;
    }
  }
}

@media (max-width: 280px) {
  footer {
    #footer-email {
      font-size: 1.3rem;
    }
  }
}
