.experience-card {
  .experience-company {
    font-weight: bolder;
  }

  .experience-date-location {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 1.5rem;

    .experience--date-location {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .experience-emoji {
      margin-right: 1rem;
    }
  }
}

@media (max-width: 750px) {
  .experience-card {
    .experience-date-location {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 620px) {
  .experience-card {
    .experience-date-location {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .experience--date-location {
        width: 100%;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .experience-card {
    .experience-position {
      font-size: 1.8rem;
    }
  }
}

@media (max-width: 455px) {
  .experience-card {
    .experience-position {
      font-size: 1.6rem;
    }
  }
}

@media (max-width: 350px) {
  .experience-card {
    .experience-date-location {
      font-size: 1rem;
    }
  }
}

@media (max-width: 310px) {
  .experience-card {
    .experience-date-location {
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 290px) {
  .experience-card {
    .experience-date-location {
      font-size: 0.8rem;
    }
  }
}
