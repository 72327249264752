$black: #00020a; // background
$white: #fcf5e7; // text
$gray: #ffffff1f; // card and tooltip
$purple: #6a4fff; // accent

$white-border: #fcf5e724; // skill border
$white-text: #fcf5e7ab; // skill text

$light-yellow: #fff7d8; // bumble section background
$yellow: #ffedb3; // bumble item
$dark-yellow: #ffc629; // bumble highlight
$dark-gray: #454650; // bumble text
