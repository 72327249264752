@import '../../Colours.scss';

#bumble {
  margin-left: 20vw;
  margin-right: 20vw;
  display: flex;
  flex-direction: column;
  width: 40vw;

  // https://codepen.io/jakejarvis/pen/pBZWZw
  .wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }

  #bumble-icecream .item {
    &:hover {
      background-color: $dark-yellow;
      cursor: pointer;
    }
  }

  ::selection {
    background-color: $dark-yellow !important;
    color: $dark-gray !important;
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme  */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 800px) {
  #bumble {
    margin-left: 0vw;
    margin-right: 0vw;
    width: 80vw;
  }
}
