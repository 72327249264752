@import '../../Colours.scss';

header {
  height: 15vh;
  margin-left: -10vw;
  margin-right: -10vw;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;

  #logo {
    font-size: 2.5rem;
    font-weight: bold;

    &:hover {
      color: $purple;
      animation: bounce 1s;
      cursor: pointer;
    }
  }
}
